export const companyComplainsTableHead = [
    {
        id: 'createdAt',
    },
    {
        id: 'title',
    },
    {
        id: 'fullName',
    },
    {
        id: 'sector',
    },
    {
        id: 'isPublic',
    },
    {
        id: 'type'
    },
    {
        id: 'number',
    },
    {
        id: 'haveComments',
    },
    {
        id: 'haveCompanyComment',
    },
    {
        id: 'status',
    },
];