import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {useAuth} from "../hooks";
import {useEffect} from "react";

export const withRouter = WrappedComponent => props => {
    const params = useParams();
    const navigate = useNavigate();
    const auth = useAuth();
    const location = useLocation();
    const { t: t_buttons } = useTranslation("Buttons");
    const { t: t_dataForm } = useTranslation("ComplainDetailsScreen");
    const { t: t_modals } = useTranslation("Modals");
    const { t: t_company } = useTranslation("CompaniesScreen");
    return (
      <WrappedComponent
        {...props}
        params={params}
        navigate={navigate}
        location={location}
        t_dataForm={t_dataForm}
        t_buttons={t_buttons}
        currentUser={auth.userData}
        isAdmin={auth.userData?.roles?.toLowerCase().includes("administrator")}
        t_modals={t_modals}
        t_company={t_company}
      />
    );
};